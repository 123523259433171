export const lowercaseKeyAndPick = (iter: URLSearchParams, keys: string[]) => {
  const obj = {};
  for (const [key, val] of iter) {
    const newKey = key.toLowerCase();
    if (keys.includes(newKey)) {
      obj[newKey] = val;
    }
  }
  return obj;
};
