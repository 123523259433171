import loadjs from 'load-js';
import { LaunchReady } from '../integrations/launch-tracking';

export default () => {
  if (window.disablelaunch) return;
  if (!window.launch_key) return;
  const launchKey: string = window.launch_key?.startsWith('launch-')
    ? `${window.launch_key}${ENV_FLAG}`
    : `launch-${window.launch_key}${ENV_FLAG}`;
  loadjs([{ url: `https://assets.adobedtm.com/${launchKey}.min.js`, async: true }])
    .then(() => {
      if (!window.ltanalytics._loaded) {
        window.ltanalytics._loaded = false;
        console.error('Launch did not validate loading with ltanalytics._loaded == true');
      }
    })
    .catch((e) => {
      window.ltanalytics._loaded = false;
      console.error('ERROR Loading Adobe Launch', e);
    })
    .finally(LaunchReady);
};
