import type { AnyObject } from '../../types';
import { getCookie } from '../common/get-cookie';
import { isDefined, isUndefined } from '../common/is-type';
import { getData } from '../integrations/lta-data';

const mktTrackCookieName = 'MKT_TRACK';
const userOriginationDataCookieName = 'UserOriginationData';

export const getParamFromQs = (param: string) => new URLSearchParams(location.search).get(param) || '';

export const getCookieObject = (cookieName: string = mktTrackCookieName) => {
  const getCookieByName = getCookie(cookieName);
  if (getCookieByName) {
    const cookieDecoded = decodeURIComponent(getCookieByName);
    if (isUndefined(cookieDecoded)) {
      return undefined;
    }
    return JSON.parse(cookieDecoded);
  }
  return undefined;
};

export const getMtaidFromCookieOrQs = () => {
  if (getData('mtaid')) {
    return getData('mtaid');
  }
  const trackingObj: AnyObject = getCookieObject();
  if (isUndefined(trackingObj)) {
    return getParamFromQs('mtaid');
  }
  return isDefined(trackingObj?.mtaid) ? trackingObj.mtaid : '';
};

export const getClickSessionId = () => {
  if (getData('ClickSessionId')) {
    return getData('ClickSessionId');
  }
  const trackingObj: AnyObject = getCookieObject();
  if (isUndefined(trackingObj) && getParamFromQs('mtaid') && getParamFromQs('sessionid')) {
    return getParamFromQs('sessionid');
  }
  return isDefined(trackingObj?.ClickSessionId) ? trackingObj.ClickSessionId : '';
};

export const getTreeAnonymousTransientUid = () => {
  if (getData('TreeAnonymousTransientUid')) {
    return getData('TreeAnonymousTransientUid');
  }
  const userOriginationDataObj: AnyObject = getCookieObject(userOriginationDataCookieName);
  if (isUndefined(userOriginationDataObj)) {
    return undefined;
  }
  return isDefined(userOriginationDataObj.TreeAnonymousTransientUid)
    ? userOriginationDataObj.TreeAnonymousTransientUid
    : '';
};

export const getMktTrackDataFromApi = (responseObj: any, cookieName: string = mktTrackCookieName) => {
  const cookieData = getCookie(cookieName, responseObj);
  if (cookieData) {
    const cookieDecoded = decodeURIComponent(cookieData);
    if (cookieDecoded) {
      return JSON.parse(cookieDecoded);
    }
  }
  return undefined;
};
