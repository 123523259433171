import { getCookie } from '../common/get-cookie';
import domainName from '../common/root-domain-name';
import { getClickSessionId } from '../handler/mkttrack-cookie-handler';

const aamCookieName = 'aam_lt';
const uuidCookieName = 'uuid_lt';
let visitorId: any;

export const buildReferralPixelTrackingProperties = (eventName: string): Record<string, string> => {
  const deviceType = navigator.userAgent.includes('iPad')
    ? 'iPad'
    : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(navigator.userAgent)
      ? 'mobile'
      : 'desktop';

  let propertyObj: any = {
    domain: domainName(),
    referrer: document.referrer,
    device: deviceType,
    eventname: eventName,
    type: 'Analytics Bootstrap',
  };

  if (window.Visitor) {
    const visitor = window.Visitor.getInstance('2E96401053DB518E0A490D4C@AdobeOrg');
    propertyObj.adobevisitorid = visitorId || visitor.getMarketingCloudVisitorID();
  }

  propertyObj.clicksessionid = getClickSessionId();

  if (eventName === 'ltanalytics_page_fires') {
    let aamCookieValue = '';
    const getURLSearchParams = new URLSearchParams(getCookie(aamCookieName));
    const aamCookie = Object.fromEntries(getURLSearchParams);
    const uuidCookieData = getCookie(uuidCookieName) || '';
    if (aamCookie) {
      aamCookieValue = aamCookie.aam_lt || '';
    }
    propertyObj = Object.assign(propertyObj, {
      aamid: aamCookieValue,
      uuid: uuidCookieData,
    });
  }
  return propertyObj || {};
};

window.buildReferralPixelTrackingProperties = buildReferralPixelTrackingProperties;

const postData = (propertyObj: Record<string, string>): void => {
  const pixelRequestUrl = new URL(`https://${PIXEL_HOSTNAME}/pixel/t`);
  fetch(pixelRequestUrl, {
    method: 'post',
    credentials: 'include',
    keepalive: true,
    body: JSON.stringify(propertyObj),
  }).catch(console.error);
};

export const sendToKafka = (eventName: string) => {
  const propertyObj = buildReferralPixelTrackingProperties(eventName);
  postData(propertyObj);
};

export const sendToKafkaOnMCVIDCallback = (eventName: string) => {
  if (window.Visitor) {
    const visitor: any = window.Visitor.getInstance('2E96401053DB518E0A490D4C@AdobeOrg');
    if (visitor.getMarketingCloudVisitorID()) {
      sendToKafka(eventName);
    } else {
      visitor.getMarketingCloudVisitorID((id: any) => {
        visitorId = id;
        sendToKafka(eventName);
      });
    }
  } else {
    sendToKafka(eventName);
  }
};

export default () => {
  if (!window.fireLaunchstrapBeginsOnce) {
    sendToKafkaOnMCVIDCallback('launch_strap_begins');
    window.fireLaunchstrapBeginsOnce = true;
  }
};
