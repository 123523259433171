import { getCookie } from '../common/get-cookie';
import { isUndefined } from '../common/is-type';
import { lowercaseKeyAndPick } from '../common/lowercase-key-and-pick';

// handlers
// constants
const marketingCookieName = 'LT_MKT_TRACK';
const marketingBaseTrackKeys = [
  'esourceid',
  'cchannel',
  'csource',
  'cterm',
  'cname',
  'cmethod',
  'ccontent',
  'ppckw',
  'adid',
  'cpad',
  'adtest',
  'siteid',
  'mid',
  '800num',
  'cspage',
  'cepage',
  'pdv',
  'ccode',
  'iid',
  'cspage_v',
  'cepage_v',
  'hpversion',
  'cproduct',
  'ccreative',
  'ctype',
  'gclid',
  'mpid',
  'cgroup',
  'ccampaign',
];

function getParams(dataStr: any) {
  const trackingObj = new URLSearchParams(dataStr);
  return lowercaseKeyAndPick(trackingObj, marketingBaseTrackKeys);
}

export function getParamsFromQueryString() {
  return getParams(location.search);
}

export function getParamsFromCookie() {
  const cookieString = getCookie(marketingCookieName);
  return getParams(cookieString);
}

export function getParamByName(name: any) {
  if (!name) {
    return undefined;
  }

  // Get Marketing Track Cookie
  const cookieObj = getParamsFromCookie();
  if (isUndefined(cookieObj)) {
    return undefined;
  }
  return cookieObj[String(name).toLowerCase()];
}

export function getMarketingDataFromApi(responseObj: any) {
  const cookieData = getCookie(marketingCookieName, responseObj);
  return getParams(cookieData);
}
