// Taken from: http://bonsaiden.github.io/JavaScript-Garden/#types.typeof

function is(type: string, obj: unknown): boolean {
  return Object.prototype.toString.call(obj).slice(8, -1) === type;
}

export function isArray(primitive: unknown): boolean {
  return is('Array', primitive);
}

export function isObject(primitive: unknown): boolean {
  return is('Object', primitive);
}

export function isString(primitive: unknown): boolean {
  return is('String', primitive);
}

export function isFunction(primitive: unknown): boolean {
  return is('Function', primitive);
}

export function isNumber(primitive: unknown): boolean {
  return is('Number', primitive);
}

export function isBoolean(primitive: unknown): boolean {
  return is('Boolean', primitive);
}

export function isUndefined(primitive: unknown): boolean {
  return is('Undefined', primitive);
}

export function isNull(primitive: unknown): boolean {
  return is('Null', primitive);
}

export function isDefined(primitive: unknown): boolean {
  return !isUndefined(primitive);
}
