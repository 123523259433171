import setSafeFunction from './includes/handler/setsafefunction';
import disableanalytics from './includes/integrations/disableanalytics';
import setAshData from './includes/integrations/lta-ash-data';
import adobelaunch from './includes/integrations/lta-onpageload-adobelaunch';
import onPageloadGtm from './includes/integrations/lta-onpageload-gtm';
import ltanalytics from './includes/integrations/ltanalytics-lta-launchstrap';
import referralPixel from './includes/integrations/referral-pixel';

const functionsArray = [disableanalytics, ltanalytics, referralPixel, setAshData, adobelaunch, onPageloadGtm];

for (const func of functionsArray) {
  setSafeFunction(func)();
}
