import type { AnyFunc } from '@/types';

export const memoize = (fn: AnyFunc): AnyFunc => {
  const cache = {};
  let key: string;
  return (...args: unknown[]): unknown => {
    key = JSON.stringify(args);
    cache[key] = cache[key] || fn.call(null, ...args);
    return cache[key];
  };
};
