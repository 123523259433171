export default (url = '') => {
  let hostName = url || window.location.hostname;
  if (hostName) {
    const parts = hostName.split('.').reverse();
    if (parts && parts.length > 1) {
      hostName = `${parts[1]}.${parts[0]}`;
    }
  }
  return hostName;
};
