import type { AnyObject } from '../../types';
import { isUndefined } from '../common/is-type';
import { memoize } from '../common/memoize';
import { getParamByName, getParamsFromQueryString } from '../handler/marketing-tracking-handler';
import { isTrackingReady } from './lta-ash-data';
import { getData } from './lta-data';

/**
 * @param {*} propertyObj the properties to be evaluated
 * @param {string} propType description of what kind of properties are being passed in
 */

const setTrackProps = memoize((isReady: boolean) => {
  let trackProperties = {};
  try {
    const trackObj = getData();

    if (isUndefined(trackObj) || isUndefined(trackObj.esourceid)) {
      const qsObj: any = getParamsFromQueryString();
      if (qsObj.esourceid) {
        Object.assign(trackObj, qsObj);
      }
    }

    if (trackObj.AnonymousId) {
      delete trackObj.AnonymousId;
    }

    if (trackObj.timestamp) {
      delete trackObj.timestamp;
    }

    trackProperties = {
      isTrackingReady: isReady,
      ...trackObj,
    };
  } catch (e) {
    console.error(e);
  }
  return trackProperties;
});

export const buildTrackProperties = (propertyObj: AnyObject = {}) => ({
  ...propertyObj,
  ...setTrackProps(isTrackingReady),
});

export default () => {
  const ltanalytics = (window.ltanalytics = window.ltanalytics || []);
  ltanalytics.methods = [
    'getAndApplyOffers',
    'trackSubmit',
    'trackClick',
    'trackLink',
    'trackForm',
    'pageview',
    'identify',
    'reset',
    'group',
    'track',
    'ready',
    'alias',
    'debug',
    'page',
    'push',
    'once',
    'off',
    'on',
    'postToKafka',
  ];

  ltanalytics.factory =
    (method: string) =>
    (...args) => {
      args.unshift(method);
      ltanalytics[ltanalytics.length] = args;
      return ltanalytics;
    };

  for (const method of ltanalytics.methods) {
    ltanalytics[method] = ltanalytics.factory(method);
  }

  window.ltanalytics = Object.assign(window.ltanalytics, {
    getParameterByName: getParamByName,
    getTrackProperties: buildTrackProperties,
  });
};
