import { getCookie, setCookie } from '../common/get-cookie';
import { getCookieObject } from '../handler/mkttrack-cookie-handler';

const neustarDataCookiename = 'Neustar_MKT_Track';
const isNeustarMKTTrack = (): boolean => !!getCookie(neustarDataCookiename);

export const setNeustarCookie = async (): Promise<void> => {
  const mktTrackCookie = getCookieObject();
  const ipReponse = await fetch('https://api.ipify.org?format=json');
  const res = await ipReponse.json();
  const ip4 = res.ip;
  const raw = JSON.stringify({
    clientId: NEUSTAR_CLIEND_ID,
    tracking: {
      sessionId: mktTrackCookie?.ClickSessionId,
    },
    fabrickRequest: {
      i4: ip4,
      '1pd': mktTrackCookie?.AnonymousId,
    },
  });
  fetch(`${NEUSTAR_URL}/api/fabrick`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    keepalive: true,
    body: raw,
  })
    .then((resp) => resp.json())
    .then((resultData) => {
      const fabResult = encodeURIComponent(JSON.stringify(resultData?.result?.fabrickResult));
      setCookie(neustarDataCookiename, fabResult, 7);
    });
};
export default (): void => {
  if (!isNeustarMKTTrack() && window.location.host.includes('lendingtree.com')) setNeustarCookie();
};
