window.dataLayer = window.dataLayer || [];

export const gtmTracking = (propertyObj, type) => {
  if (propertyObj && Object.keys(propertyObj).length > 0) {
    if (type === 'page') {
      const name =
        propertyObj.category && propertyObj.name ? `${propertyObj.category} ${propertyObj.name}` : propertyObj.name;

      propertyObj.event = name ? `Viewed ${name} Page` : 'Loaded a Page';
    } else {
      propertyObj.event = propertyObj.name || propertyObj.trackEventName || '';
    }
    window.dataLayer.push(propertyObj);
  }
};
