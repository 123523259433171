import gtm from '../../../lib/gtm-library-load';
import sgtm from '../../../lib/sgtm-library-load';
import onTrackingReady from '../common/on-tracking-ready';

export default () => {
  onTrackingReady(() => {
    gtm();
    sgtm();
  });
};
