import { lowercaseKeyAndPick } from '../common/lowercase-key-and-pick';

const ProductBaseTrackKeys = [
  'icode',
  'icid',
  'agent_name',
  '_mid',
  'mailingname',
  'promotion-experience',
  'spid',
  'lpid',
];

export default {
  get() {
    const queryStringObj = new URLSearchParams(location.search);
    return lowercaseKeyAndPick(queryStringObj, ProductBaseTrackKeys);
  },
};
