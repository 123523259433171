import type { AnyObject } from '../../types';
import { isFunction, isObject, isString } from '../common/is-type';
import { gtmTracking } from '../handler/gtm-tracking-handler';
import { buildTrackProperties } from './ltanalytics-lta-launchstrap';

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {};

const sendTrackingCall = (eventType: string, detail: AnyObject) => {
  // making call asynchronous
  setTimeout(() => {
    if (window.ltanalytics._loaded) {
      const event = new CustomEvent(eventType, { detail });
      document.documentElement.dispatchEvent(event);
    }
    // Send data to GTM
    if (!window.disablegtm) {
      let type = 'page';
      if (eventType === 'lt-analytics-track') {
        type = 'track';
      }
      gtmTracking(detail, type);
    }
  }, 100);
};

export const trackCallData = (name, propertyObj, options, callback) => {
  // Argument reshuffling.
  if (isFunction(options)) {
    callback = options;
    options = null;
  }
  if (isFunction(propertyObj)) {
    callback = propertyObj;
    options = null;
    propertyObj = null;
  }
  if (!name) name = '';
  propertyObj = propertyObj || {};
  propertyObj.name = name;
  propertyObj.eventName = name;

  // call adobe event
  sendTrackingCall('lt-analytics-track', buildTrackProperties(propertyObj));

  // fire whatever code needs to execute after the custom event is fired
  if (isFunction(callback)) {
    setTimeout(() => {
      callback();
    }, 300);
  }
};

export const pageCallData = (category, name, propertyObj, options, callback) => {
  // Argument reshuffling.
  if (isFunction(options)) {
    callback = options;
    options = null;
  }
  if (isFunction(propertyObj)) {
    callback = propertyObj;
    options = propertyObj = null;
  }
  if (isFunction(name)) {
    callback = name;
    options = propertyObj = name = null;
  }
  if (isObject(category)) {
    options = name;
    propertyObj = category;
    name = category = null;
  }
  if (isObject(name)) {
    options = propertyObj;
    propertyObj = name;
    name = null;
  }
  if (isString(category) && !isString(name)) {
    name = category;
    category = null;
  }
  if (!category) category = '';
  if (!name) name = '';
  propertyObj = propertyObj || {};
  propertyObj.category = category;
  propertyObj.name = name;
  propertyObj.eventName = name;

  // call adobe event
  sendTrackingCall('lt-analytics-page', buildTrackProperties(propertyObj));

  // fire whatever code needs to execute after the custom event is fired
  if (isFunction(callback)) {
    setTimeout(() => {
      callback();
    }, 300);
  }
};

export const pushCallData = (item) => {
  if (isFunction(item)) {
    item();
  } else if (Array.isArray(item)) {
    const event = item.shift();
    if (!isFunction(window.ltanalytics[event])) return;
    window.ltanalytics[event].apply(this, item);
  }
};

export const identifyData = (userId) => {
  if (!userId) return;
  try {
    window.localStorage.setItem('ltanalytics_user_id', userId);
  } catch (e) {
    console.error(e);
  }
};

const isEmptyObject = (obj: AnyObject) => obj && Object.keys(obj).length === 0;

const convertKeysToLowercase = (obj: AnyObject) => {
  const newObj = {};
  let key: string;
  for (key in obj) {
    newObj[key.toLowerCase()] = obj[key];
  }
  return newObj;
};

const getCommonTrackingProperties = () => ({
  path: window.location.pathname || '',
  domain: window.location.hostname || '',
  query: window.location.search || '',
  timestamp: Date.now(),
});

const postToKafka = (propertyObj, callback) => {
  if (isFunction(propertyObj)) {
    callback = propertyObj;
    propertyObj = null;
  }
  let props: AnyObject = {};
  let marketingProps: AnyObject = {};
  let commonProps: AnyObject = {};

  if (isObject(propertyObj) && !isEmptyObject(propertyObj)) {
    props = convertKeysToLowercase(propertyObj);
    if (isObject(props.eventdata) && !isEmptyObject(props.eventdata)) {
      if (!props.eventname) props.eventname = 'CrossSell Tracking Event';

      // To set eventmarketingdata parameter
      marketingProps = buildTrackProperties();
      if (!isEmptyObject(marketingProps)) props.eventmarketingdata = marketingProps;

      // To set eventcommontrackingdata parameter
      commonProps = getCommonTrackingProperties();
      if (!isEmptyObject(commonProps)) props.eventcommontrackingdata = commonProps;

      if (!isEmptyObject(props)) {
        if (props.eventname === 'displayed' || props.eventname === 'clicked') {
          if (
            props.eventdata.displaying_app === 'offers-ui' ||
            props.eventdata.displaying_app === 'template-renderer'
          ) {
            props['qf-product'] = props.eventdata.product || '';
            props.qformuid = props.eventdata.qformuid || '';
            props['qf-match-status'] = props.eventdata.offers_page_match_state || '';
            props['qf-variation'] = props.eventdata.offers_voffers || '';
            props.offers_fvoffers = props.eventdata.offers_fvoffers || '';
          }
          props.trackEventName = 'Kafka Displayed/Clicked Payload';
          sendTrackingCall('lt-analytics-track', props);
        }
      }
    } else if (!isObject(props.eventdata)) {
      console.warn(
        `LT Launch/tracking calls/postToKafka: EventData is not in proper foramt. Current Format: ${typeof props.eventdata}`,
      );
    }
  } else if (!isObject(propertyObj)) {
    console.warn(
      `LT Launch/tracking calls/postToKafka: CrossSell data is not in proper foramt. Current Format: ${typeof propertyObj}`,
    );
  }

  if (isFunction(callback)) {
    setTimeout(() => {
      callback();
    }, 300);
  }
};

export const LaunchReady = () => {
  window.ltanalytics = Object.assign(window.ltanalytics, {
    track: trackCallData,
    page: pageCallData,
    push: pushCallData,
    ready: pushCallData,
    identify: identifyData,
    postToKafka,

    // deprecated methods listed below:
    getAndApplyOffers: noop,
    trackSubmit: noop,
    trackClick: noop,
    trackLink: noop,
    trackForm: noop,
    pageview: noop,
    reset: noop,
    group: noop,
    alias: noop,
    debug: noop,
    once: noop,
    off: noop,
    on: noop,
  });

  let ltaEvent: any;

  while ((ltaEvent = window.ltanalytics.shift())) {
    pushCallData(ltaEvent);
  }
};
