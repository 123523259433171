import { isFunction, isObject, isString } from '../common/is-type';

let dataObj = {};

export const setData = (obj) => {
  if (isObject(obj)) {
    dataObj = Object.assign(dataObj, obj);
  }
};

export const reducer = (obj, key) => {
  const val = obj[key];
  if (isFunction(val)) {
    obj[key] = val();
  } else {
    obj[key] = val;
  }
  return obj;
};

export const getData = (str?) => {
  if (isString(str)) {
    const returnObj = reducer({ ...dataObj }, str);
    return returnObj[str || ''];
  }
  return Object.keys(dataObj).reduce(reducer, { ...dataObj });
};
