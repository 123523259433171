export default () => {
  if (window.disablegtm) {
    return ['', ''];
  }

  const hostName = window.location.hostname;
  const pathName = window.location.pathname.toLowerCase();

  /*
   Each host name will correlate with an ibis. domain and a GTM container ID.
   For example, any lendingtree.com url that is NOT /forms/ will use GTM-PB2FNQ and will call ibis.lendingtree.com
   If the host name is offers.magnifymoney.com, the GTM container ID will be GTM-MXN3F4 and will call ibis.magnifymoney.com
   We need to ensure that the correct GTM container ID and ibis. domain are being called for each host name/path name combination
  */

  // Define the whitelisted base URLs
  const baseUrls = [
    ['www.', 'lendingtree', '.com'],
    ['myoffers.', 'lendingtree', '.com'],
    ['spring.', 'lendingtree', '.com'],
    ['auth.', 'lendingtree', '.com'],
    ['www.', 'simpletuition', '.com'],
    ['www.', 'depositaccounts', '.com'],
    ['www.', 'comparecards', '.com'],
    ['creditcards.', 'lendingtree', '.com'],
    ['business.', 'lendingtree', '.com'],
    ['www.', 'magnifymoney', '.com'],
    ['', 'snapcap', '.com'],
    ['www.', 'valuepenguin', '.com'],
  ];
  const environments = ['', 'dev.', 'staging.', 'preprod.'];

  // Generate the whitelist
  const sgtmWhiteList = baseUrls.flatMap(([subdomain, domain, tld]) =>
    environments.map((env) => {
      if (domain === 'comparecards' && env !== '') {
        return `${env}${domain}${tld}`;
      }
      if (domain === 'depositaccounts' && env !== '') {
        return `${subdomain}${env}internal.${domain}${tld}`;
      }
      if (domain === 'magnifymoney' && env === 'staging.') {
        return `stag.${domain}${tld}`;
      }
      return `${subdomain}${env}${domain}${tld}`;
    }),
  );

  // Function to check if a hostname is whitelisted
  const isWhiteListed = (hostname) => sgtmWhiteList.includes(hostname);

  const sgtmContainerMapping = [
    {
      stringToMatch: hostName,
      stringToCompare: 'myoffers.', // ex: myoffers.lendingtree.com
      gtmContainerId: 'GTM-M87M9C',
      containerUrl: 'ibis.lendingtree.com',
    },
    {
      stringToMatch: hostName,
      stringToCompare: 'business.', // ex: www.bisuness.lendingtree.com
      gtmContainerId: 'GTM-KW3CKJQ',
      containerUrl: 'ibis.lendingtree.com',
    },
    {
      stringToMatch: pathName,
      stringToCompare: '/offers', // ex: www.lendingtree.com/offers
      gtmContainerId: 'GTM-M87M9C',
      containerUrl: 'ibis.lendingtree.com',
    },
    {
      stringToMatch: pathName,
      stringToCompare: '/form/', // ex: www.lendingtree.com/form/
      gtmContainerId: 'GTM-W5DKKXF',
      containerUrl: 'ibis.lendingtree.com',
    },
    {
      stringToMatch: pathName,
      stringToCompare: '/forms/', // ex: www.lendingtree.com/forms/
      gtmContainerId: 'GTM-W5DKKXF',
      containerUrl: 'ibis.lendingtree.com',
    },
    {
      stringToMatch: hostName,
      stringToCompare: 'comparecards.com', // ex: www.comparecards.com/
      gtmContainerId: 'GTM-TC9GVZ',
      containerUrl: 'ibis.comparecards.com',
    },
    {
      stringToMatch: hostName,
      stringToCompare: 'creditcards.', // ex: www.creditcards.lendingtree.com/
      gtmContainerId: 'GTM-TC9GVZ',
      containerUrl: 'ibis.comparecards.com',
    },
    {
      stringToMatch: hostName,
      stringToCompare: 'spring.', // ex: www.spring.lendingtree.com/
      gtmContainerId: 'GTM-PS7KWZ',
      containerUrl: 'ibis.lendingtree.com',
    },
    {
      stringToMatch: hostName,
      stringToCompare: 'auth.', // ex: www.auth.lendingtree.com/
      gtmContainerId: 'GTM-PS7KWZ',
      containerUrl: 'ibis.lendingtree.com',
    },
    {
      stringToMatch: hostName,
      stringToCompare: 'snapcap', // ex: snapcap.com/
      gtmContainerId: 'GTM-56LB2SR',
      containerUrl: 'ibis.snapcap.com',
    },
    {
      stringToMatch: hostName,
      stringToCompare: 'depositaccounts.com', // ex: www.depositaccounts.com/
      gtmContainerId: 'GTM-PMML277',
      containerUrl: 'ibis.depositaccounts.com',
    },
    {
      stringToMatch: hostName,
      stringToCompare: 'magnifymoney.com', // ex: www.magnifymoney.com/
      gtmContainerId: 'GTM-MXN3F4',
      containerUrl: 'ibis.magnifymoney.com',
    },
    {
      stringToMatch: hostName,
      stringToCompare: 'simpletuition', // ex: www.simpletuition.com/
      gtmContainerId: 'GTM-N26JN9',
      containerUrl: 'ibis.simpletuition.com',
    },
    {
      stringToMatch: hostName,
      stringToCompare: 'valuepenguin.com', // ex: www.valuepenguin.com/
      gtmContainerId: 'GTM-M7BTDDP',
      containerUrl: 'ibis.valuepenguin.com',
    },
    {
      stringToMatch: hostName,
      stringToCompare: 'lendingtree.com',
      gtmContainerId: hostName.startsWith('www.') && !hostName.includes('comparecards') ? 'GTM-PB2FNQ' : '',
      containerUrl: 'ibis.lendingtree.com',
    },
  ];

  // Do not load GTM if the hostname is not in the whitelist
  if (!isWhiteListed(hostName)) {
    return ['', ''];
  }

  let gtmContainerId = '';
  let containerUrl = '';
  for (const app of sgtmContainerMapping) {
    if (app.stringToMatch.includes(app.stringToCompare)) {
      gtmContainerId = app.gtmContainerId;
      containerUrl = app.containerUrl;
      if (gtmContainerId) {
        break;
      }
    }
  }

  if (!gtmContainerId) {
    return ['', ''];
  }

  if (!window.google_tag_manager || (window.google_tag_manager && !window.google_tag_manager[gtmContainerId])) {
    ((w, d, s, l, i) => {
      w[l] = w[l] || [];
      w[l].push({
        'gtm.start': new Date().getTime(),
        event: 'gtm.js',
      });
      const f = d.getElementsByTagName(s)[0];
      const j = d.createElement(s);
      const dl = l === 'dataLayer' ? '' : `&l=${l}`;
      j.async = true;
      j.src = `https://${containerUrl}/gtm.js?id=${i}${dl}`;
      f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', gtmContainerId);
  }
  return [gtmContainerId, containerUrl];
};
